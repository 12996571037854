<template>
  <div class="page-content-inner pt-lg-0  pr-lg-0 mt-4">
    <nav id="breadcrumbs" class="mb-3">
      <ul>
        <li><a href="#"> <i class="uil-home-alt"></i> </a></li>
        <li> {{ this.$t('general.requests_my_demands') }}</li>
        <li> {{ this.$t('general.user_requests') }}</li>
      </ul>
    </nav>

    <div class="d-flex justify-content-between mb-3">
      <h3> {{ this.$t('general.user_requests') }} ({{ userRequestCount }}) </h3>
    </div>

    <div class="section-header pb-0">
      <div class="section-header-left">
        <input class="mt-3" type="text" v-model="searchQuery" @input="isTyping = true"
          placeholder="Kullanıcı İsteklerinde Ara">
      </div>

      <div class="section-header-right">
        <select v-model="filters.order_by" class="selectpicker ml-3 mb-0" @change="applyFilter">
          <option value="name">{{ $t('filter.Filter_by_name') }}</option>
          <option value="training_type_id">{{ $t('filter.Filter_by_category') }}</option>
          <option value="created_at">{{ $t('filter.Filter_by_date') }}</option>
        </select>
      </div>
    </div>
    <div class="uk-child-width-1-4@m mt-4" uk-grid
      uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200">
      <div v-for="(item, index) in items.data" :key="'training_list_item' + index">
        <div class="card animate-this uk-inline-clip" v-if="item">
          <img :src="item.cover_image_link" :alt="item.name" style="height:180px">
          <div class="card-body text-center pb-3">
            <h6 class=" mb-0" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap">{{ item.name }}</h6>
            <span>{{ item.training_type ? item.training_type.name : '' }}</span>
          </div>
          <div class="card-body text-center p-3">
            <a @click="handleOnClickCard(item.id)" href="javascript:void(0)" type="button"
              class="btn btn-secondary btn-sm mb-2">
              <i class="uil-search"></i>{{ $t('general.review_demo') }}
            </a>
            <a @click="handleOnClickCard(item.id)" href="javascript:void(0)" type="button"
              class="btn btn-success btn-sm mb-2">
              <i class="uil-cog"></i> Detaylar
            </a>
          </div>
          <div class="card-footer py-0 border-top-0">
            <div class="row align-items-center text-center">
              <div class="col-12 py-3">
                <p style="color:#ec5252;font-weight:600;"><i class="uil-bell"></i> {{ item.users_pending_requests }}
                  kişi
                  istekte bulundu </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
    </Pagination>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import { GET_ITEMS, ITEMS } from "@/core/services/store/REST.module";
import { mapGetters } from 'vuex'

import trainingModule, {
  MODULE_NAME as TRAINING_MODULE_NAME,
  REQUEST_COUNT as TRAINING_USER_REQUEST_COUNT,
} from "@/core/services/store/training.module";
import store from "@/core/services";

const _TRAINING_MODULE_NAME = TRAINING_MODULE_NAME;

export default {
  name: "EducationRequestList",
  components: {
    EducationCard,
    Pagination
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_TRAINING_MODULE_NAME, trainingModule);
  },
  data() {
    return {
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      restUrl: 'api/training-requests',
      trainingUserRequestUrl: "api/training-requests?count=true",
      educationTypes: [],
      filters: {
        page: 1,
        search: '',
        order_by: 'name',
        sort: 'desc',
        per_page: 16,
        with_requests: true
      },
    };
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
    }),
    userRequestCount() {
      return store.getters[_TRAINING_MODULE_NAME + "/" + TRAINING_USER_REQUEST_COUNT];
    },
    pageProxy: {
      set(value) {
        this.filters.page = value
        this.applyFilter()
      },
      get() {
        return this.page;
      }
    }
  },
  methods: {
    handleOnClickCard(payload) {
      this.$router.push({ name: 'company.requests.training', params: { id: payload } })
    },
    fixDate(value) {
      return moment(value).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY');
    },
    applyFilter(searchQuery = null) {

      this.$set(this.filters, 'page', this.filters.page)
      this.$set(this.filters, 'search', this.searchQuery)
      this.$set(this.filters, 'order_by', this.filters.order_by)
      this.$set(this.filters, 'with_requests', this.filters.with_requests)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters,
      })
    },
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.applyFilter(this.searchQuery);
      }
    }
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      return false;
    }
    this.applyFilter()
    $(".selectpicker").selectpicker();
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
